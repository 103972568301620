import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Link } from "react-router-dom";
import { backendUrl } from './backendUrl';
import styles from './assets/login.module.css';

const Login = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [mode, setMode] = useState('login');
  let history = useHistory();

  useEffect(() => {
    setError(false);
  }, [mode]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  }

  const handleSubmit = (e) => {
    if (mode === "login") {
      handleLogin(e);
    } else {
      handleSignup(e);
    }
  }

  const handleLogin = (e) => {
    e.preventDefault();
    axios
      .post(`${backendUrl}/auth/login/`, {
        user: {
          username: username,
          password: password
        }
      })
      .then(res => {
        const token = res.data.token;
        localStorage.setItem("token", token);
        history.push("/");
      })
      .catch(err => {
        setError(true);
      });
  };

  const handleSignup = (e) => {
    e.preventDefault();
    axios
      .post(`${backendUrl}/auth/signup/`, {
        user: {
          username: username,
          email: email,
          password: password
        }
      })
      .then(res => {
        const token = res.data.token;
        localStorage.setItem("token", token);
        history.push("/");
      })
      .catch(err => {
        setError(true);
      });
  }

  const handleChange = (e) => {
    if (error) setError(false);

    switch (e.target.name) {
      case "username":
        setUsername(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        break;
      case "password":
        setPassword(e.target.value);
        break;
      default:
    }
  }

  const SingupForm = <form className={styles.form_group}>
    <p className={styles.error_message}>{error && <span>Invalid username or password</span>}</p>
    <div className={styles.input_group}>
      <label>Username</label>
      <input
        type="text"
        className={styles.modal_input}
        name="username"
        onChange={handleChange}
        placeholder="Enter username"
        required
      />
    </div>
    <div className={styles.input_group}>
      <label>Email</label>
      <input
        type="email"
        className={styles.modal_input}
        name="email"
        onChange={handleChange}
        placeholder="Enter email"
        required
      />
    </div>
    <div className={styles.input_group}>
      <label>Password</label>
      <input
        type="password"
        className={styles.modal_input}
        name="password"
        onChange={handleChange}
        placeholder="Enter password"
        required
      />
    </div>
    <button
      onClick={handleSignup}
      className={styles.modal_button}
      // loading={props.loading}
      disabled={props.loading}
    >
      <span>SIGN UP</span>
    </button>
  </form>

  const LoginForm = <form className={styles.form_group}>
    <p className={styles.error_message}>{error && <span>Incorrect username or password</span>}</p>
    <div className={styles.input_group}>
      <label>Username</label>
      <input
        type="text"
        className={styles.modal_input}
        name="username"
        onChange={handleChange}
        placeholder="Enter username"
        required
      />
    </div>
    <div className={styles.input_group}>
      <label>Password</label>
      <input
        type="password"
        className={styles.modal_input}
        name="password"
        onChange={handleChange}
        placeholder="Enter password"
        required
      />
    </div>
    <button
      onClick={handleLogin}
      className={styles.modal_button}
      // loading={props.loading}
      disabled={props.loading}
    >
      <span>SIGN IN</span>
    </button>
    <Link to="/send_reset_password">Forgot Password?</Link>
  </form>

  return (
    <div className='form-page__wrapper'>
        <div className={styles.popup_modal}>
          <div className={styles.header}>
            <div className={styles.tab_bar}>
              <span
                className={`${styles.mode_tab} ${mode === "login" && styles.tab_selected}`}
                onClick={() => setMode("login")}
              >
                SIGN IN
            </span>
              <span
                className={`${styles.mode_tab} ${mode === "signup" && styles.tab_selected}`}
                onClick={() => setMode("signup")}
              >
                SIGN UP
            </span>
            </div>
          </div>
          <div className={styles.body}>
            {mode === "login" && LoginForm}
            {mode === "signup" && SingupForm}
          </div>
          <div className={styles.footer}></div>
        </div>
    </div>
  )
};

export default Login;