import React, { useState, useEffect } from 'react';
import { Table, Tag, Checkbox, Popconfirm, Form, Typography, Input } from 'antd';
import { EditFilled, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import axios from 'axios';
import { backendUrl } from './backendUrl';
import "antd/dist/antd.css";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'checkbox' ? <Checkbox /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          valuePropName="checked"
          style={{
            margin: 0,
          }}
          initialValue={record[dataIndex]}
        >
          {inputNode}
        </Form.Item>
      ) : (
          children
        )}
    </td>
  );
};

const Admin = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');

  useEffect(() => {
    axios
      .get(`${backendUrl}/labels/`, {
        headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
      })
      .then(res => {
        if (res.status !== 200) props.handleFail();
        setData(res.data);
      })
      .catch(err => {
        console.log(err);
        props.handleFail();
      });
  }, []);

  const updateLabels = (newLabels) => {
    axios
      .post(`${backendUrl}/labels/`, {
        labels: newLabels
      }, {
        headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
      })
      .then(res => {
        if (res.status !== 201) console.log('update failed.');
      })
      .catch(err => {
        console.log(err);
      });
  };

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      standard: '',
      asin: '',
      coo: '',
      single: '',
      auto: '',
      ...record,
    });
    form.resetFields();
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        updateLabels({username: item.username, ...row});
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      key: 'is_staff',
      dataIndex: 'is_staff',
      render: val => (
        <Tag color={val ? 'geekblue' : 'green'}>
          {val ? 'Admin' : 'User'}
        </Tag>
      ),
    },
    {
      title: 'Standard',
      dataIndex: 'standard',
      key: 'standard',
      editable: true,
      editType: 'checkbox',
      render: val => (val ? "Yes" : "No")
    },
    {
      title: 'Standard + ASIN',
      dataIndex: 'asin',
      key: 'asin',
      editable: true,
      editType: 'checkbox',
      render: val => (val ? "Yes" : "No")
    },
    {
      title: 'Standard + COO',
      dataIndex: 'coo',
      key: 'coo',
      editable: true,
      editType: 'checkbox',
      render: val => (val ? "Yes" : "No")
    },
    {
      title: 'Single Barcode',
      dataIndex: 'single',
      key: 'single',
      editable: true,
      editType: 'checkbox',
      render: val => (val ? "Yes" : "No")
    },
    {
      title: 'Auto Generate',
      dataIndex: 'auto',
      key: 'auto',
      editable: true,
      editType: 'checkbox',
      render: val => (val ? "Yes" : "No")
    },
    {
      title: 'Edit',
      dataIndex: 'edit',
      width: "7%",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Popconfirm title="Are you sure to save the changes?" onConfirm={() => save(record.key)}>
              <a
                style={{
                  marginRight: 8,
                }}
              >
                <CheckOutlined />
              </a>
            </Popconfirm>
            &nbsp;
            <a onClick={cancel}><CloseOutlined /></a>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            <EditFilled />
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.editType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div className="admin-container">
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
        />
      </Form>
    </div>
  )
};

export default Admin;