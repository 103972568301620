import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { Button, Spinner } from 'react-bootstrap';
import { useIdleTimer } from 'react-idle-timer';
import Select, { components } from 'react-select'
import axios from 'axios';
import csv from "csv";

import ProgressBar from './progressbar';
import { PDFDownloadLink } from '@react-pdf/renderer'
import PdfDocument, { Barcode } from './pdfdocument';
import Admin from './admin';

import LogoIcon from './assets/whrep_logo.png';
import UploadIcon from './assets/upload.png';
import { LeftOutlined, LogoutOutlined, ControlOutlined, HomeOutlined } from '@ant-design/icons';
import StandardImage from './assets/standard_image.png';
import AsinImage from './assets/asin_image.png';
import CooImage from './assets/coo_image.png';
import StandardSampleCSV from './assets/Upload_Sample.csv';
import AsinSampleCSV from './assets/Upload_Sample_w_ASIN.csv';
import CooSampleCSV from './assets/Upload_Sample_w_COO.csv';
import MUSampleCSV from './assets/Locations R12.csv';
import { backendUrl } from './backendUrl';


const IndicatorsContainer = props => {
  return (
    <div style={{ background: "rgb(82, 67, 170)" }}>
      <components.IndicatorsContainer {...props} />
    </div>
  );
};

const Home = (props) => {
  const [mode, setMode] = useState("init");
  const [products, setProducts] = useState([]);
  const [status, setStatus] = useState("standby");
  const [header, setHeader] = useState([]);
  const [count, setCount] = useState(0);
  const [admin, setAdmin] = useState(false);
  const [panel, setPanel] = useState(false);
  const [modeOptions, setModeOptions] = useState([]);
  let history = useHistory();

  const onDrop = useCallback(acceptedFiles => {
    const reader = new FileReader();

    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading failed");
    reader.onloadstart = () => setStatus("reading");
    reader.onload = () => {
      // Parse CSV file
      csv.parse(reader.result, (err, data) => {
        if (!data) return;
        setHeader(data[0]);
        let prodData = data.slice(1, data.length)
        let generated;
        if (data[0].length === 1) { // MU
          prodData = data;
          generated = data.map((product, index) => (
            <div className="label" key={index}>
              <Barcode value={product[0]} index={index} />
            </div>
          ));
        } else if (data[0].length === 4) { // Standard
          generated = prodData.map((product, index) => (
            <div className="label" key={index}>
              <Barcode value={product[1]} index={index} />
            </div>
          ));
        } else { // ASIN, COO
          generated = prodData.map((product, index) => (
            <div className="label" key={index}>
              <Barcode value={product[1]} index={index} />
              <Barcode value={product[4]} index={index} />
            </div>
          ));
        }
        let container = document.getElementById("labels");
        ReactDOM.render(generated, container);
        setProducts(prodData);
      });
    };

    // read file contents
    acceptedFiles.forEach((file) => reader.readAsBinaryString(file));
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.csv, .xls, .xlsx'
  });

  const files = acceptedFiles.map((file, index) => (
    <span key={index}>
      File: {file.path} - {file.size} bytes
    </span>
  ));

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      history.push('/login');
    }

    axios
      .get(`${backendUrl}/auth/me/`, {
        headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
      })
      .then(res => {
        setCount(res.data.counter.label_count);
        const label = res.data.label;
        let options = [];
        if (label.standard) options.push({ value: "standard", label: "Standard WHREP Label" });
        if (label.asin) options.push({ value: "asin", label: "WHREP Label + ASIN Barcode" });
        if (label.coo) options.push({ value: "coo", label: "WHREP Label + Country of Origin" });
        if (label.single) options.push({ value: "single", label: "Single Barcode Labels" });
        if (label.auto) options.push({ value: "auto", label: "Auto Generate MUs" });

        setModeOptions(options);

        if (res.data.is_staff) setAdmin(true);
      });
  }, []);

  const handleLogout = event => {
    localStorage.removeItem("token");
    history.push("/login");
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleLogout,
    debounce: 500
  })

  const handleSubmit = () => {
    setStatus("inprogress");
    axios
      .post(`${backendUrl}/counter/`, {}, {
        headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
      })
      .then(res => {
        setCount(res.data.label_count);
      });
  };

  let SampleCSV;
  const labelTitles = {
    "standard": "Standard WHREP Label",
    "asin": "WHREP Label + ASIN Barcode",
    "coo": "WHREP Label + Country of Origin",
    "single": " Single Barcode Labels",
    "auto": "Auto Generate MUs"
  };

  if (mode === "standard") {
    SampleCSV = StandardSampleCSV;
  } else if (mode === "asin") {
    SampleCSV = AsinSampleCSV;
  } else if (mode === "coo") {
    SampleCSV = CooSampleCSV;
  } else if (mode === "single") {
    SampleCSV = MUSampleCSV;
  }

  const handlerMode = ({ value }) => {
    setMode(value);
    if (value === "auto") {
      setStatus("reading");
    }
  }

  const handleAdmin = () => {
    setPanel(!panel);
  }

  useEffect(() => {
    if (mode === "auto") {
      let data = [];
      const today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      var startDate = parseInt(yyyy + mm + dd + '0001');
      for (let i = 0; i < 500; i++) {
        data.push([startDate + i]);
      }

      let generated = data.map((product, index) => (
        <div className="label" key={index}>
          <Barcode value={product[0]} index={index} />
        </div>
      ));
      let container = document.getElementById("labels");
      ReactDOM.render(generated, container);
      setProducts(data);
    }
  }, [mode]);

  return (
    <div>
      <div className="logo">
        { admin 
? <Button variant="outline-secondary" style={{ width: "70px", fontSize: "30px" }} onClick={handleAdmin}>{ panel ? <HomeOutlined /> : <ControlOutlined />}</Button>
        : <div style={{ width: "70px" }} />
        }
        <a href="https://warehouserepublic.com"><img src={LogoIcon} /></a>
        <Button variant="outline-secondary" style={{ width: "70px", fontSize: "30px" }} onClick={handleLogout}><LogoutOutlined /></Button>
      </div>
      <div className="container">
        {panel 
        ? <Admin handleFail={() => setPanel(false)}/>
        : <>
            {mode === "init"
            ? <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ textAlign: "center" }}><h3>What would you like to do?</h3></span>
                <br />
                <Select
                  components={{ IndicatorsContainer }}
                  options={modeOptions}
                  onChange={handlerMode}
                />
                <div>
                  <br />
                  <br />
                  <b>
                    Instructions: <br />
                    1) Pick Label Type (Most folks use the Standard WHREP Label) <br />
                    2) Download the Sample CSV for that specific label type to generate specific labels
                  </b>
                </div>
                <br />
                <br />
                <br />
                <br />
                <div style={{ textAlign: "center" }}><b><h3>Labels Printed: {count}</h3></b></div>
              </div>
            : <>
              {(status === "standby" || status === "reading") &&
                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                  <div style={{ textAlign: "center", marginBottom: "30px" }}>
                    <span>
                      <h2 style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <span style={{ display: "flex" }} onClick={() => setMode('init')} ><LeftOutlined className="backLeft" /></span>
                        {labelTitles[mode]}
                      </h2>
                    </span>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div>
                      <h3 style={{ textAlign: "center" }}>Sample Label</h3>
                      {mode === "standard" && <img src={StandardImage} />}
                      {mode === "asin" && <img src={AsinImage} />}
                      {mode === "coo" && <img src={CooImage} />}
                    </div>
                    <div style={{ marginTop: "30px" }}>
                      {mode != "auto" &&
                        <div className="upload-container">
                          <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <img className="upload-icon" src={UploadIcon} alt="upload-icon" />
                            <p>Drag and drop the file here</p><span>or click to upload a file</span>
                          </div>
                          <aside>
                            {files}
                          </aside>
                        </div>}
                      <div className="footer">
                        {mode != "auto" && <a href={SampleCSV} download="Upload_Sample.csv">Click to download sample csv file</a>}
                        <Button variant="primary" block disabled={products.length === 0} onClick={() => handleSubmit()}>
                          {status === "reading" && products.length === 0
                            ? <Spinner animation="border" role="status"><span className="sr-only">Loading...</span>
                            </Spinner>
                            : <span>Submit</span>}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              }
              {status === "inprogress" &&
                <div className="footer">
                  <span>Generating labels...please wait.</span>
                  <ProgressBar onComplete={() => setStatus("completed")} />
                </div>
              }
              {status === "completed" &&
                <div className="footer">
                  <span>Labels generated.</span>
                  <PDFDownloadLink
                    style={{ width: "100%" }}
                    document={<PdfDocument header={header}
                      products={products}
                      mode={mode}
                      pheight={144}
                      pwidth={288} />}
                    fileName="labels.pdf"
                  >
                    {({ blob, url, loading, error }) => (
                      <Button block disabled={loading}>{loading ? <span><Spinner animation="border" size="sm" role="status" />Loading Document...</span> : 'Download now!'}</Button>
                    )}
                  </PDFDownloadLink>
                  <Button style={{ marginTop: "20px" }} block onClick={() => window.location.reload()}>Back to Start</Button>
                </div>
              }
              </>
            }
          <div id="labels" className="labels">
          </div>
          </>
        }
      </div>
    </div>
  );
}

export default Home;