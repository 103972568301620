import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Switch } from 'react-router-dom';
import Home from './home';
import Login from './login';
import NotFound from './notfound';
import ResetPassword from "./resetpassword";
import ResetPasswordConfirm from "./resetpasswordconfirm";

import './assets/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route path="/send_reset_password/" component={ResetPassword} />
          <Route path="/reset_password/:uid/:token" component={ResetPasswordConfirm} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
